import script from "./CenterDialog.vue?vue&type=script&setup=true&lang=ts"
export * from "./CenterDialog.vue?vue&type=script&setup=true&lang=ts"

import "./CenterDialog.vue?vue&type=style&index=0&id=86617416&lang=scss"

const __exports__ = script;

export default __exports__
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QSpace from 'quasar/src/components/space/QSpace.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../../node_modules/@quasar/app-webpack/lib/webpack/runtime.auto-import.js";
qInstall(script, 'components', {QDialog,QCard,QCardSection,QIcon,QCardActions,QBtn,QSpace});qInstall(script, 'directives', {ClosePopup});
