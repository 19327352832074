import { useSessionStorage } from "@vueuse/core";
import { defineStore } from "pinia";
import { tokenType } from "src/types/constType";
import { computed, Ref } from "vue";

type TGeneral = {
  isApp: boolean;
  isMobile: boolean;
  appType: string;
  token: tokenType;
  isMainError: boolean;
  isOrganizationLogin: boolean;
  organizationUuid: string;
};

export const useGeneralStore = defineStore("general", () => {
  const general: Ref<TGeneral> = useSessionStorage(
    `alzguard_${process.env.VUE_APP_ENV}/general`,
    { isApp: false, isMobile: false } as TGeneral
  );
  const getIsApp = computed(() => general.value.isApp);

  function setIsApp(appFlag: boolean) {
    general.value.isApp = appFlag;
  }

  const getIsMobile = computed(() => general.value.isMobile);
  function setIsMobile(mobileFlag: boolean) {
    general.value.isMobile = mobileFlag;
  }

  const getAppType = computed(() => general.value.appType);

  function setAppType(appType: string) {
    general.value.appType = appType;
  }

  const getToken = computed(() => general.value.token);

  function setToken(newToken: tokenType) {
    general.value.token = { ...newToken };
  }

  const getIsMainError = computed(() => general.value.isMainError);

  function setIsMainError(mainErrorFlag: boolean) {
    general.value.isMainError = mainErrorFlag;
  }

  const getIsOrganizationLogin = computed(
    () => general.value.isOrganizationLogin
  );

  function setIsOrganizationLogin(organizationLoginFlag: boolean) {
    general.value.isOrganizationLogin = organizationLoginFlag;
  }

  const getOrganizationUuid = computed(() => general.value.organizationUuid);

  function setOrganizationUuid(organizationUuid: string) {
    general.value.organizationUuid = organizationUuid;
  }

  return {
    getIsApp,
    setIsApp,
    getIsMobile,
    setIsMobile,
    getAppType,
    setAppType,
    getToken,
    setToken,
    getIsMainError,
    setIsMainError,
    getIsOrganizationLogin,
    setIsOrganizationLogin,
    getOrganizationUuid,
    setOrganizationUuid,
  };
});
